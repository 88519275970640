<template>
  <div>
    <div
      v-if="
        $route.path === '/login' ||
        $route.path === '/forgot-password' ||
        $route.path === '/password-reset' ||
        $route.path === '/register' ||
        $route.path === '/'
      "
    >
      <router-view />
    </div>
    <div v-else-if="authIsLogged">
      <div>
        <nav class="uk-navbar-container uk-box-shadow-medium" uk-navbar>
          <div class="uk-flex">
            <div class="uk-navbar-left">
              <a
                id="navigation-menu"
                dusk="navigation-menu"
                class="uk-navbar-toggle uk-hidden@m"
                uk-navbar-toggle-icon
                href="#offcanvas"
                uk-toggle
              ></a>
            </div>
            <div class="uk-navbar-left">
              <router-link
                to="/"
                class="uk-navbar-item uk-logo vertical-divider"
              >
                <img
                  :src="api + '/storefronts/' + storefrontId + '/logo'"
                  width="150px"
                  alt
                />
              </router-link>
              <ul class="uk-navbar-nav uk-visible@m">
                <li
                  v-for="(route, index) in mainRoutes"
                  :key="index"
                  :class="{
                    'uk-active': route.path === currentPath,
                  }"
                >
                  <router-link :to="route.path">{{ route.name }}</router-link>
                </li>
              </ul>
            </div>
          </div>

          <div class="uk-navbar-right">
            <ul class="uk-navbar-nav uk-flex-middle">
              <li v-if="storefrontStorefront.type === 'redeem'">
                <router-link to="/points">
                  <div
                    style="height: 35px"
                    class="
                      uk-margin-right
                      uk-text-default
                      uk-badge
                      uk-link-reset
                      uk-flex
                      uk-flex-middle
                    "
                  >
                    <div v-if="pointsIcon">
                      <img :src="pointsIcon" alt="" width="70px" />
                    </div>
                    <div class="uk-margin-small-left">
                      {{ formatThousands(pointsBalance) }}
                      {{ pointsName }} disponibles
                    </div>
                  </div>
                </router-link>
              </li>
              <li class="uk-visible@s">
                <div>
                  <form
                    class="uk-search uk-search-muted"
                    @submit="performSearch($event)"
                  >
                    <span uk-search-icon></span>
                    <input
                      v-on:keydown.enter="$event.stopPropagation()"
                      class="uk-search-input uk-border-rounded navbar-search"
                      type="search"
                      placeholder="Buscar"
                      v-model="search"
                    />
                  </form>
                </div>
              </li>
              <li>
                <a href="#" test="nav-dropdown-arrow">
                  <div class="uk-flex uk-flex-middle">
                    <router-link
                      to="/profile"
                      class="uk-link-reset"
                      test="profile-navbar-link"
                      >{{ authUser.first_name }} {{ authUser.last_name }}
                    </router-link>
                    <div
                      uk-icon="chevron-down"
                      class="uk-margin-small-left"
                    ></div>
                  </div>
                </a>
                <div class="uk-navbar-dropdown">
                  <ul class="uk-nav uk-navbar-dropdown-nav">
                    <li v-for="(route, index) in dropdownRoutes" :key="index">
                      <router-link :to="route.path">
                        <div class="uk-flex uk-flex-middle">
                          <div :uk-icon="route.icon"></div>
                          <div class="uk-margin-small-left">
                            {{ route.name }}
                          </div>
                        </div>
                      </router-link>
                    </li>
                    <li @click="logout" test="logout-button">
                      <div class="uk-flex uk-flex-middle cursor-pointer">
                        <div uk-icon="sign-out"></div>
                        <div class="uk-margin-small-left">Cerrar sesión</div>
                      </div>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </nav>
      </div>

      <div
        class="
          uk-background-primary
          text-white
          uk-padding-small uk-flex-center uk-visible@m uk-grid-small
        "
        uk-grid
      >
        <div
          v-for="category in productsCategories.filter(
            (category) => category.product_category_id === null
          )"
          :key="'category-' + category.id"
        >
          <router-link
            test="navbar-category-link"
            :to="{
              path: '/store/search',
              query: {
                category: category.id,
              },
            }"
            class="uk-link-reset"
            >{{ category.name }}
            <span
              v-if="category.children.length !== 0"
              uk-icon="chevron-down"
            ></span
          ></router-link>
          <div
            uk-drop="pos: bottom-center"
            v-if="category.children.length !== 0"
          >
            <div
              class="
                uk-card uk-card-primary uk-text-center uk-border-rounded
                category-card
              "
            >
              <div
                v-for="category in category.children"
                :key="'category-' + category.id"
              >
                <router-link
                  class="uk-link-reset"
                  :to="'/store/search?category=' + category.id"
                >
                  <div class="uk-padding-small">
                    {{ category.name
                    }}<span
                      v-if="category.children.length !== 0"
                      uk-icon="chevron-right"
                    ></span>
                  </div>
                </router-link>
                <div
                  uk-drop="pos: right-top"
                  v-if="category.children.length !== 0"
                >
                  <div
                    class="
                      uk-card uk-card-primary uk-text-center uk-border-rounded
                      category-card
                    "
                  >
                    <div
                      v-for="category in category.children"
                      :key="'category-' + category.id"
                    >
                      <router-link
                        class="uk-link-reset"
                        :to="'/store/search?category=' + category.id"
                      >
                        <div class="uk-padding-small">
                          {{ category.name }}
                        </div>
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="uk-container uk-position-relative">
        <div id="content-container" class="uk-margin-bottom"></div>
        <router-view />
        <div class="uk-margin-bottom"></div>
      </div>

      <div id="offcanvas" uk-offcanvas="mode: push; overlay: true">
        <div class="uk-offcanvas-bar uk-background-primary">
          <div class="uk-panel">
            <div>
              <form
                class="uk-search uk-search-default uk-width-1-1"
                @submit="performSearch($event)"
              >
                <span uk-search-icon></span>
                <input
                  v-on:keydown.enter="$event.stopPropagation()"
                  class="uk-search-input uk-border-rounded navbar-search"
                  type="search"
                  placeholder="Buscar"
                  v-model="search"
                />
              </form>
            </div>
            <ul class="uk-nav uk-nav-default uk-margin-small-top">
              <li>
                <ul uk-accordion>
                  <li
                    v-for="category in productsCategories.filter(
                      (category) =>
                        category.product_category_id === null &&
                        category.children.length !== 0
                    )"
                    :key="'category-' + category.id"
                  >
                    <router-link
                      :to="'/store/search?category=' + category.id"
                      class="uk-link-reset"
                      :class="{
                        'uk-accordion-title': category.children.length !== 0,
                      }"
                      >{{ category.name }}
                      <span
                        v-if="category.children.length !== 0"
                        uk-icon="chevron-down"
                      ></span
                    ></router-link>
                    <div
                      class="uk-accordion-content"
                      v-if="category.children.length !== 0"
                    >
                      <router-link
                        v-for="category in category.children"
                        :key="'category-' + category.id"
                        class="uk-link-reset"
                        :to="'/store/search?category=' + category.id"
                      >
                        <div class="uk-padding-small">
                          {{ category.name }}
                        </div>
                      </router-link>
                    </div>
                  </li>
                </ul>
              </li>
              <li
                v-for="category in productsCategories.filter(
                  (category) =>
                    category.product_category_id === null &&
                    category.children.length === 0
                )"
                :key="'category-' + category.id"
              >
                <router-link :to="'/store/search?category=' + category.id">{{
                  category.name
                }}</router-link>
              </li>
              <li
                v-for="(route, index) in mainRoutes"
                :key="index + route.path"
                :class="{ 'uk-active': route.path === currentPath }"
              >
                <router-link :to="route.path">{{ route.name }}</router-link>
              </li>

              <li
                v-for="(route, index) in dropdownRoutes"
                :key="index + route.path"
                :class="{ 'uk-active': route.path === currentPath }"
              >
                <router-link :to="route.path">{{ route.name }}</router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Navigation",

  data() {
    return {
      search: "",
    };
  },

  computed: {
    ...mapGetters([
      "authUser",
      "authIsLogged",
      "productsCategories",
      "pointsBalance",
      "pointsName",
      "pointsIcon",
      "storefrontStorefront",
    ]),
    mainRouteList() {
      return [
        {
          name: "Tienda",
          path: "/store",
          visible_for: ["all"],
          storefront_type: ["all"],
        },
        {
          name:
            this.storefrontStorefront.type === "b2b"
              ? "Mis cotizaciones"
              : "Mis canjes",
          path: "/quotes",
          visible_for: ["client"],
          storefront_type: ["all"],
        },
        {
          name: "Contacto",
          path: "/contact",
          visible_for: ["client"],
          storefront_type: ["all"],
        },
        {
          name: "Revisión de cotizaciones",
          path: "/quotes/review",
          visible_for: [
            "administrator",
            "storefront-administrator",
            "salesman",
          ],
          storefront_type: ["b2b"],
        },
        // {
        //   name: "Reportes",
        //   path: "/reports",
        //   visible_for: ["administrator", "storefront-administrator"],
        //   storefront_type: ["all"],
        // },
      ];
    },

    dropdownRouteList() {
      return [
        {
          name: "Empresas",
          path: "/businesses",
          visible_for: ["administrator", "storefront-administrator"],
          storefront_type: ["b2b"],
          icon: "thumbnails",
        },
        {
          name: "Usuarios",
          path: "/users",
          visible_for: ["administrator", "storefront-administrator"],
          storefront_type: ["all"],
          icon: "users",
        },
        {
          name: "Equipos de venta",
          path: "/sale-teams",
          visible_for: ["administrator", "storefront-administrator"],
          storefront_type: ["b2b"],
          icon: "receiver",
        },
        {
          name: "Sincronización de productos",
          path: "/product-sync",
          visible_for: ["administrator"],
          storefront_type: ["all"],
          icon: "database",
        },
        {
          name: "Productos incompletos",
          path: "/products/incomplete",
          visible_for: ["administrator"],
          storefront_type: ["all"],
          icon: "warning",
        },
        {
          name: "Restricción de productos",
          path: "/products/restrictions",
          visible_for: ["administrator"],
          storefront_type: ["all"],
          icon: "minus-circle",
        },
        {
          name: "Configuración de constantes",
          path: "/constants",
          visible_for: ["administrator"],
          storefront_type: ["all"],
          icon: "hashtag",
        },
        {
          name: "Nova",
          path: "/internal/nova",
          visible_for: ["administrator", "storefront-administrator"],
          storefront_type: ["all"],
          icon: "sign-in",
        },
      ];
    },
    currentPath() {
      return window.location.pathname;
    },
    mainRoutes() {
      if (this.authUser) {
        return this.mainRouteList
          .filter(
            (route) =>
              route.visible_for.includes(this.authUser.type.slug) ||
              route.visible_for.includes("all")
          )
          .filter(
            (route) =>
              route.storefront_type.includes(this.storefrontStorefront.type) ||
              route.storefront_type.includes("all")
          );
      } else {
        return [];
      }
    },
    dropdownRoutes() {
      if (this.authUser) {
        return this.dropdownRouteList
          .filter(
            (route) =>
              route.visible_for.includes(this.authUser.type.slug) ||
              route.visible_for.includes("all")
          )
          .filter(
            (route) =>
              route.storefront_type.includes(this.storefrontStorefront.type) ||
              route.storefront_type.includes("all")
          );
      } else {
        return [];
      }
    },
  },

  mounted() {
    if (this.authIsLogged && this.storefrontStorefront.type === "redeem") {
      this.pointsFetchBalance();
    }
    this.storefrontFetchStorefront();
  },

  methods: {
    ...mapActions([
      "logout",
      "pointsFetchBalance",
      "storefrontFetchStorefront",
    ]),
    performSearch(event) {
      if (event) {
        event.preventDefault();
      }
      // window.location = "/store/search?query=" + this.search;
      this.$router.push("/store/search?query=" + this.search);
      this.search = "";
    },
  },
};
</script>

<style>
.category-card {
  border: solid 2px rgb(56, 56, 56);
}

.navbar-search {
  padding: 15px 30px;
}

.text-white {
  color: white !important;
}

.background-white {
  background-color: white !important;
}
</style>


<style lang="scss">
@font-face {
  font-family: Nexa;
  src: url("./assets/fonts/NexaBold.otf");
  font-weight: bold;
}
@font-face {
  font-family: Nexa;
  src: url("./assets/fonts/Nexa Light.otf");
  font-weight: normal;
}

$global-font-family: "Nexa" !default;
$global-color: black;
$global-muted-background: #eee;
$container-max-width: 1800px;
$border-rounded-border-radius: 15px;

// UIKIT
@import "uikit/src/scss/variables.scss";
@import "uikit/src/scss/mixins.scss";
@import "uikit/src/scss/uikit.scss";

.dashed-border {
  border: 2px dashed;
}

// v-select
$vs-selected-bg: #dce0e6;
$vs-controls-color: black;
$vs-colors: (
  lightest: rgba(60, 60, 60, 0.26),
  light: rgba(60, 60, 60, 0.5),
  dark: #666,
  darkest: rgba(0, 0, 0, 0.15),
) !default;
$vs-border-style: none !default;
$vs-dropdown-max-height: 200px !default;
@import "vue-select/src/scss/vue-select.scss";
.vs__dropdown-toggle {
  padding: 0;
  height: 40px;
  background: #f8f8f8;
}
.vs__selected-options {
  padding: 0;
}

.vs__selected {
  margin: 0;
}
.v-select {
  padding: 0;
}

// Required Core Stylesheet
@import "node_modules/@glidejs/glide/src/assets/sass/glide.core";
@import "node_modules/@glidejs/glide/src/assets/sass/glide.theme";

.glide__bullet {
  background-color: lightgrey;
}
.glide__bullet:hover {
  background-color: lightgrey;
  border: none;
}
.glide__bullet:focus {
  background-color: $global-primary-background;
  border: none;
}
.glide__bullet--active {
  background-color: $global-primary-background;
}

.glide__bullets {
  bottom: 0;
}

.cursor-pointer {
  cursor: pointer;
}
</style>
